<template>
  <div class="nolte-dealer-map" />
</template>

<script>
import gmapsInit from '../../lib/gmaps';

const mapOptions = {
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
};

const INITIAL_ZOOM_LEVEL = 3;
const INITIAL_ADDRESS = 'Nolte Küchen GmbH und Co. KG, Anni-Nolte-Straße 4, 32584 Löhne';

export default {
  name: 'NolteDealerMap',

  props: {
    dealers: {
      type: Array,
      default: undefined,
    },
    currentAddress: {
      type: String,
      default: undefined,
    },
    currentMarkerId: {
      type: String,
      default: undefined,
    },
    googleMapsApiKey: {
      type: String,
      default: undefined,
    },
    markerPaddingTop: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      google: null,
      map: null,
      geocoder: null,
      markers: null,
    };
  },

  async mounted() {
    try {
      this.google = await gmapsInit(this.googleMapsApiKey, 'places,geometry');
      this.map = new this.google.maps.Map(this.$el, mapOptions);
      this.geocoder = new this.google.maps.Geocoder();

      this.$watch(
        'currentMarker',
        currentMarker => {
          // TODO: compare if marker changed
          if (!currentMarker) {
            return;
          }
          this.focusMarker(currentMarker);
        },
        { immediate: true }
      );

      this.$watch(
        'dealers',
        dealers => {
          if (!dealers?.length) {
            this.showDefaultLocation();
          } else {
            this.setMarkers();
          }
        },
        { immediate: true }
      );

      this.$watch('currentAddress', () => {
        this.showDefaultLocation();
      });
    } catch (error) {
      console.error(error);
    }
  },

  computed: {
    currentMarker() {
      if (!this.markers?.length || !this.currentMarkerId) {
        return;
      }

      return this.markers.find(({ id }) => id === this.currentMarkerId);
    },
  },

  methods: {
    focusMarker({ instance }) {
      this.map.setZoom(13);
      this.map.setCenter(instance.getPosition());
    },

    setMarkers() {
      this.clearMarkers();

      // prepare marker format for google maps API
      if (!this.dealers?.length) {
        return;
      }

      this.markers = this.dealers.map(({ position, id }) => {
        const markerInstance = new this.google.maps.Marker({
          position: {
            lat: position.latitude,
            lng: position.longitude,
          },
          map: this.map,
        });

        const marker = {
          instance: markerInstance,
          id,
        };

        markerInstance.addListener('click', () => {
          this.$emit('dealerSelected', id);
          return this.focusMarker(marker);
        });

        return marker;
      });
      this.showAllMarkers();
    },

    clearMarkers() {
      if (this.markers?.length) {
        this.markers.forEach(({ instance }) => instance.setMap(null));
      }
      this.markers = null;
    },

    showAllMarkers() {
      const markerBoundary = new this.google.maps.LatLngBounds();
      this.markers.forEach(({ instance }) => markerBoundary.extend(instance.position));

      // add padding to respect search bar
      // https://developers.google.com/maps/documentation/javascript/reference/map#Map.fitBounds
      this.map.fitBounds(markerBoundary, { top: this.markerPaddingTop });
    },

    showLocation(address, callback) {
      this.clearMarkers();

      try {
        this.geocoder.geocode({ address }, (results, status) => {
          if (status !== 'OK' || !results[0]) {
            throw new Error(status);
          }
          this.map.setCenter(results[0].geometry.location);

          if (typeof callback === 'function') {
            callback(results, status);
          }
        });
      } catch (e) {
        console.log(e);
        console.log(`Can't find address`);
      }
    },

    showDefaultLocation() {
      if (!this.currentAddress) {
        this.showLocation(INITIAL_ADDRESS, () => {
          this.map.setZoom(INITIAL_ZOOM_LEVEL);
        });
      } else {
        this.showLocation(this.currentAddress, results => {
          this.map.fitBounds(results[0].geometry.viewport, { top: this.markerPaddingTop });
        });
      }
    },
  },
};
</script>
