<template>
  <div class="nolte-dealer-finder-data-provider">
    <slot
      :searchInput="searchInput"
      :setSearchInput="setSearchInput"
      :address="address"
      :setAddress="setAddress"
      :dealers="dealers"
      :distanceUnit="distanceUnit"
      :countryOptions="countryOptions"
      :setCountryByCode="setCountryByCode"
      :countryCode="countryCode"
      :countryName="currentCountryName"
      :googleMapsApiKey="googleMapsApiKey"
    />
  </div>
</template>

<script>
import qs from 'qs';
import camelcaseKeys from 'camelcase-keys';

import { removeCurlyBracesFromGuid, formatSearchResult, dataFetcher } from './utils.js';

const batchSize = 15;
const defaultDistanceUnit = 'km';

// on production we don't need the environment variable
const dealerApiBaseUrl = process.env.VUE_APP_API_BASE_URL || '/Retailersearch/';

const dealerApiDefaultParams = {
  defaultRadiusGuid: 'F30A150E-C5FE-45A8-95BF-DA47981CFFF3',
  countryGuid: 'DDF6EDF1-F62F-4651-B3A8-32A72488A570',
  from: 0,
  batchSize,
};

export default {
  name: 'NolteDealerFinderDataProvider',

  props: {
    siteLanguageCode: {
      type: String,
      required: true,
    },
    siteCountryCode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      searchInput: null,
      address: null,

      dealerFinderOptions: null,
      countryCode: null,

      dealers: null,
      totalHits: null,
      position: null,
    };
  },

  created() {
    // watch multiple properties for single callback
    // https://github.com/vuejs/vue/issues/844#issuecomment-335026928
    this.$watch(
      vm => [vm.siteCountryCode, vm.siteLanguageCode].join(),
      async () => {
        this.dealerFinderOptions = await this.getDealerFinderOptions({
          country: this.siteCountryCode,
          language: this.siteLanguageCode,
        });
      },
      { immediate: true }
    );

    this.$watch(
      vm => [vm.address, vm.currentCountryGuid].join(),
      async () => {
        const { dealers, totalHits, position } = await this.getSearchResult({
          address: this.address,
          countryGuid: this.currentCountryGuid,
        });

        this.dealers = dealers;
        this.totalHits = totalHits;
        this.position = position;
      },
      { immediate: true }
    );
  },

  computed: {
    distanceUnit() {
      return this.dealerFinderOptions?.radiusOptions?.[0]?.unit || defaultDistanceUnit;
    },

    googleMapsApiKey() {
      return this.dealerFinderOptions?.googleMapsApiKey;
    },

    countryOptions() {
      if (!this.dealerFinderOptions?.countryOptions) {
        return;
      }

      return (
        this.dealerFinderOptions.countryOptions
          .map(({ name, id, acronym }) => ({
            label: name,
            value: acronym,
            id: removeCurlyBracesFromGuid(id),
          }))
          // sort by label
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    },

    currentCountryName() {
      if (!this.countryCode || !this.countryOptions) {
        return;
      }

      return this.countryOptions.find(({ value }) => value === this.countryCode)?.label;
    },

    currentCountryGuid() {
      if (!this.countryCode || !this.countryOptions) {
        return;
      }

      return this.countryOptions.find(({ value }) => value === this.countryCode)?.id;
    },
  },

  methods: {
    setSearchInput(searchInput) {
      this.searchInput = searchInput;
    },

    resetSearch() {
      this.searchInput = null;
      this.address = null;
    },

    setAddress(address) {
      this.address = address;
    },

    setCountryByCode(countryCode) {
      this.countryCode = countryCode;

      // reset address if country changes
      this.resetSearch();
    },

    async getSearchResult({ address, countryGuid }) {
      if (!address || !countryGuid) {
        return [];
      }

      try {
        const { data } = await dataFetcher(
          dealerApiBaseUrl + 'Search',
          qs.stringify({
            ...dealerApiDefaultParams,
            countryGuid,
            zipCity: address,
          })
        );

        return formatSearchResult(data);
      } catch (e) {
        console.log(e);
        return;
      }
    },

    async getDealerFinderOptions({ language, country }) {
      if (!country || !language) {
        console.log('return');
        return;
      }

      try {
        const { data } = await dataFetcher(
          dealerApiBaseUrl + 'Options',
          qs.stringify({ lang: `${language}-${country}` })
        );

        return camelcaseKeys(data, { deep: true });
      } catch (e) {
        console.log(e);
        console.log('no results?');
        return;
      }
    },
  },

  watch: {
    dealerChanged(dealers) {
      if (!dealers.length) {
        console.log('no dealers');
        return;
      }
      this.$emit('dealersChanged');
    },

    // in case we want to select a default country in the future we can use this.
    //   siteCountryCode: {
    //     immediate: true,
    //     handler(siteCountryCode) {
    //       // only set if no country already chosen
    //       if (!this.countryCode) {
    //         this.setCountryByCode(siteCountryCode);
    //       }
    //     },
    //   },
  },
};
</script>
